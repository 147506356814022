
import $ from 'jquery';
window.$ = $;
window.jquery = $;

import 'popper.js';
import 'bootstrap';
import 'paper-kit-2/assets/js/bootstrap-switch.min.js';
import 'paper-kit-2/assets/js/paper-kit.js';
import LazyLoad from "vanilla-lazyload";

var Flickity = require('flickity-fade');
require('flickity-bg-lazyload');
window.Flickity = Flickity;

$(function () {
    var ll = new LazyLoad();
    ll.update();

    if (window.scrollY > 50) {
        $('nav.navbar').removeClass('navbar-transparent');
    }

    $('.js-nav-item').click(function () {
        let href = $(this).attr('href');
        $('html,body').animate({
            scrollTop: $(href).position().top - 100,
        }, 400);
        return false;
    });

    var flkty = new Flickity('.js-currency', {
        cellAlign: 'center',
        contain: true,
        wrapAround: false,
        adaptiveHeight: true,
        autoPlay: true,
        pageDots: false,
    });
});